import { computed, getCurrentInstance, ref, watch } from 'vue';
import { CustomColumn } from '@/common/utils/types';
import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { useSlideDetailApi } from '@/alert/components/alertDetail/alertDetail.use';
import {
  clearAlertRuleControllerAxios,
  getCurrentAlertRuleControllerAxios,
} from '@/openapi/alert/api/alert-rule-controller-api';
import { AlertCurrentItem } from '@/openapi/alert/model';
import {
  AlertDetailTabProps,
  RuleInfoProps,
} from '@/alert/components/alertDetail/alertDetail.types';
import { confirmMsg, showErrorMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import {
  clearSystemRuleControllerAxios,
  getCurrentSystemRuleControllerAxios,
} from '@/openapi/systemAlert/api/system-rule-controller-api';
import { TARGET_TYPE, useAlertTagsAndTargets } from '@/alert/utils/tagsAndTargets.uses';
import { useSlideDetailStore } from '@/common/stores/slide-detail';
import { CHECK_BY_COMBINATION, CHECK_BY_TARGET, NOTI_SENT_INFO } from '@/alert/utils/define';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { AlertRowFormatter, useEventTypeValue } from '@/alert/components/alertGrid/alertGrid.uses';
import { isArray } from 'lodash-es';
import { makeRowToObj } from '@/common/utils/gridUtils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { useRolePermission } from '@/common/permission/permission.utils';

const BASE_GRID_COLUMNS: CustomColumn[] = [
  {
    caption: 'Alert Name',
    field: 'alertName',
    type: 'string',
  },
  {
    caption: 'Last Alert',
    field: 'lastAlert',
    type: 'string',
    rendererType: 'label',
  },
  {
    caption: 'Target',
    field: 'targets',
    type: 'string',
    rendererType: 'chip-cell',
  },
  {
    caption: 'First Triggered',
    field: 'firstTriggered',
    type: 'string',
  },
  {
    caption: 'Last Triggered',
    field: 'lastTriggered',
    type: 'string',
  },
  {
    caption: 'Duration (sec)',
    field: 'duration',
    type: 'number',
    rendererType: 'sec',
  },
  {
    caption: 'Alert Value',
    field: 'value',
    type: 'string',
    sortable: false,
  },
  // {
  //   caption: 'Description',
  //   field: 'description',
  //   type: 'string',
  // },
  {
    caption: 'Noti Sent',
    field: 'notiSent',
    hide: true,
  },
  {
    caption: 'Rule ID',
    field: 'ruleId',
    type: 'string',
    hide: true,
  },
];

export const setup = (props: AlertDetailTabProps) => {
  const { t } = useInternational();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { callApi, abortApi } = useSlideDetailApi();
  const { isPermissionDenied } = useRolePermission();

  const periodInfo = ref<TimePeriodInfo>({
    ...getInitPeriodInfo(),
    isPaused: true,
  });
  const ruleCriteria = computed(() => props.ruleInfo.ruleCriteria || CHECK_BY_TARGET);
  const gridColumns = computed<CustomColumn[]>(() => {
    if (ruleCriteria.value === CHECK_BY_COMBINATION) {
      return [
        {
          caption: 'Rule Name',
          field: 'ruleName',
          type: 'string',
        },
        ...BASE_GRID_COLUMNS,
      ];
    }

    return BASE_GRID_COLUMNS;
  });

  const gridData = ref<any[]>([]);

  const checkedRows = ref<any[]>([]);
  const checkedObjRows = computed(() =>
    isArray(checkedRows.value?.[0])
      ? checkedRows.value.map((row) => makeRowToObj(row, gridColumns.value))
      : checkedRows.value,
  );

  const searchWord = ref('');

  const { makeAlertRows, makeAlertTreeRows } = useAlertTagsAndTargets();

  const makeRowsController = {
    [CHECK_BY_TARGET]: makeAlertRows,
    [CHECK_BY_COMBINATION]: makeAlertTreeRows,
  };

  const setGridData = (rows: AlertCurrentItem[], formatter: AlertRowFormatter = {}) => {
    searchWord.value = '';
    checkedRows.value = [];

    if (!rows?.length) {
      gridData.value = [];
    }

    const makeRows = makeRowsController[ruleCriteria.value];
    gridData.value = makeRows({
      data: rows,
      columns: gridColumns.value,
      targetFieldName: 'target',
      targetType: TARGET_TYPE.TARGETS,
      formatter: {
        notiSent: (v) => NOTI_SENT_INFO[v] ?? '-',
        ...(formatter || {}),
      },
      hasNullRow: true,
    });
  };

  const dataSettingController = {
    user: async (ruleInfo: RuleInfoProps) => {
      if (!ruleInfo.ruleId) {
        setGridData([]);
        return;
      }
      const { data, error } = await callApi({
        fn: getCurrentAlertRuleControllerAxios,
        params: {
          alertRuleId: ruleInfo.ruleId,
        },
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT,
      });
      if (!error && data) {
        setGridData(data);
      }
    },
    system: async (ruleInfo: RuleInfoProps) => {
      if (!ruleInfo.platform || !ruleInfo.event) {
        setGridData([]);
        return;
      }
      const { data, error } = await callApi({
        fn: getCurrentSystemRuleControllerAxios,
        params: {
          systemKind: ruleInfo.platform,
          systemEvent: ruleInfo.event,
        },
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT,
      });
      if (!error && data) {
        setGridData(data, {
          alertName: () => ruleInfo.name,
        });
      }
    },
  };
  const onUpdatedIndicator = async (info: TimePeriodInfo) => {
    periodInfo.value = info;
    if (!props.isShow || !props.ruleInfo?.type || info.isPaused) {
      return;
    }

    await dataSettingController[props.ruleInfo.type]?.(props.ruleInfo);
  };

  const clearController = {
    user: async (rowData: any[], ruleInfo: RuleInfoProps) => {
      if (!rowData?.length) {
        return;
      }
      const clearRequests = rowData.map((item) => {
        const targets =
          ruleInfo.ruleCriteria === CHECK_BY_COMBINATION
            ? item.children?.map((child) => child.targets || []).flat()
            : item.targets;
        const targetsRequest = targets.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id,
        }));
        return {
          alertRuleId: ruleInfo.ruleId,
          targetsRequest,
        };
      });
      await clearAlertRuleControllerAxios({
        clearRequests,
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT_CLEAR,
      });
    },
    system: async (rowData: any[], ruleInfo: RuleInfoProps) => {
      const clearRequests = rowData.map((item) => {
        const targetsRequest = item.targets?.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id,
        }));
        return {
          ruleId: item.ruleId,
          systemEvent: ruleInfo.event,
          systemKind: ruleInfo.platform,
          targetsRequest,
        };
      });
      await clearSystemRuleControllerAxios({
        request: { clearRequests },
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT_CLEAR,
      });
    },
  };
  const onClearAlert = () => {
    const isDenied = isPermissionDenied({
      type: 'action',
      rolePermissionKey: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_LIST_CLEAR,
    });
    if (isDenied) {
      return;
    }

    const { type } = props.ruleInfo;
    confirmMsg(ctx, {
      msgStr: t('MESSAGE.DELETE'),
      okCallback: async () => {
        try {
          await clearController[type](checkedObjRows.value, props.ruleInfo);
          showSuccessMsg(ctx, t('MESSAGE.ALERT_DELETED'));
          await onUpdatedIndicator(periodInfo.value);
        } catch (e: any) {
          const { status } = e?.response ?? {};
          if (status !== 406) {
            showErrorMsg(ctx, t('MESSAGE.ALERT_DELETED_FAIL'));
          }
        }
      },
    });
  };

  const { isShowMessageWindow, eventAlertInfo, onClickValueCell } = useEventTypeValue();
  const onClickCell = async ({ field, value, row }) => {
    if (field === 'value') {
      await onClickValueCell({ value, row, ruleId: props.ruleInfo.ruleId });
    }
  };

  let prevIsPaused = false;
  watch(isShowMessageWindow, (val) => {
    if (val) {
      prevIsPaused = periodInfo.value.isPaused;
      periodInfo.value.isPaused = true;
    } else {
      periodInfo.value.isPaused = prevIsPaused;
    }
  });

  const slideDetailStore = useSlideDetailStore();
  watch(
    () => [props.isShow, props.ruleInfo],
    async ([isShow]) => {
      if (isShow) {
        slideDetailStore.setIsDetailLoading(true);
        await onUpdatedIndicator({
          ...periodInfo.value,
          isPaused: false,
        });
        slideDetailStore.setIsDetailLoading(false);
      } else {
        periodInfo.value.isPaused = true;
        setGridData([]);
        abortApi();
      }
    },
    { immediate: true },
  );

  return {
    periodInfo,
    gridColumns,
    gridData,
    checkedRows,
    searchWord,
    onUpdatedIndicator,
    onClearAlert,

    isShowMessageWindow,
    eventAlertInfo,
    onClickCell,
    t,
  };
};
